import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './components/map/map.component';
import { GameAreaComponent } from './pages/game-area/game-area.component';
import { GameStatusComponent } from './components/game-status/game-status.component';
import { IntelComponent } from './components/intel/intel.component';
import { RecordComponent } from './components/record/record.component';
import { BriefComponent } from './components/brief/brief.component';
import { ChatterComponent } from './components/chatter/chatter.component';
import { DebriefingPopupComponent } from './components/debriefing-popup/debriefing-popup.component';
import { ClockComponent } from './components/clock/clock.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { PlayerListComponent } from './components/player-list/player-list.component';
import { RoundComponent } from './components/round/round.component';
import { ContainerPipe } from './pipes/container.pipe';
import { FormsModule } from '@angular/forms';
import { PlayerFilterPipe } from './pipes/player-filter.pipe';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { DeployPopupComponent } from './components/deploy-popup/deploy-popup.component';
import { AssaultPopupComponent } from './components/assault-popup/assault-popup.component';
import { Ng5SliderModule } from 'ng5-slider';
import { AngularDraggableModule } from 'angular2-draggable';
import { DiceInfoPipe } from './pipes/dice-info.pipe';
import { DiceResultComponent } from './components/dice-result/dice-result.component';
import { ReservesPopupComponent } from './components/reserves-popup/reserves-popup.component';
import { ReserveComponent } from './components/reserve/reserve.component';
import { DebugComponent } from './components/debug/debug.component';
import { RecordEntryComponent } from './components/record-entry/record-entry.component';
import { RegionInfoComponent } from './components/region-info/region-info.component';
import { RegionNamePipe } from './pipes/region-name.pipe';
import { PlayerInfoComponent } from './components/player-info/player-info.component';
import { PlayerNamePipe } from './pipes/player-name.pipe';
import { PlayerColorPipe } from './pipes/player-color.pipe';
import { PlayerContainerPipe } from './pipes/player-container.pipe';
import { RegionHighlightDirective } from './directives/region-highlight.directive';
import { PlayerRegionsHighlightDirective } from './directives/player-regions-highlight.directive';
import { ReinforcePopupComponent } from './components/reinforce-popup/reinforce-popup.component';
import { NumberSpinnerComponent } from './components/number-spinner/number-spinner.component';
import { ChatterMessageComponent } from './components/chatter-message/chatter-message.component';
import { AutosizeModule } from 'ngx-autosize';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AbsPipe } from './pipes/abs.pipe';
import { VictoryPopupComponent } from './components/victory-popup/victory-popup.component';
import { PausedPopupComponent } from './components/paused-popup/paused-popup.component';
import { CancelledPopupComponent } from './components/cancelled-popup/cancelled-popup.component';
import { TeamSelectionPopupComponent } from './components/team-selection-popup/team-selection-popup.component';
import { PlayerSlotsComponent } from './components/player-slots/player-slots.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        Ng5SliderModule,
        AngularDraggableModule,
        AutosizeModule,
        TooltipModule,
        TranslateModule
    ],
    declarations: [
        MapComponent,
        GameAreaComponent,
        GameStatusComponent,
        IntelComponent,
        RecordComponent,
        BriefComponent,
        ChatterComponent,
        DebriefingPopupComponent,
        ClockComponent,
        SecondsToTimePipe,
        PlayerListComponent,
        RoundComponent,
        ContainerPipe,
        PlayerFilterPipe,
        ColorSelectorComponent,
        ActionButtonComponent,
        DeployPopupComponent,
        AssaultPopupComponent,
        DiceInfoPipe,
        DiceResultComponent,
        ReservesPopupComponent,
        ReserveComponent,
        DebugComponent,
        RecordEntryComponent,
        RegionInfoComponent,
        RegionNamePipe,
        PlayerInfoComponent,
        PlayerNamePipe,
        PlayerColorPipe,
        PlayerContainerPipe,
        RegionHighlightDirective,
        PlayerRegionsHighlightDirective,
        ReinforcePopupComponent,
        NumberSpinnerComponent,
        ChatterMessageComponent,
        AbsPipe,
        VictoryPopupComponent,
        PausedPopupComponent,
        CancelledPopupComponent,
        TeamSelectionPopupComponent,
        PlayerSlotsComponent
    ],
    exports: [GameAreaComponent]
})
export class GameModule { }
