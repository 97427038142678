import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MapRegion } from '@game/models/ui-map';
import { Options } from 'ng5-slider';

@Component({
    selector: 'app-reinforce-popup',
    templateUrl: './reinforce-popup.component.html',
    styleUrls: ['./reinforce-popup.component.scss']
})
export class ReinforcePopupComponent implements OnInit, OnChanges {

    troopsToReinforce = 0;
    availableTroops = 0;

    sliderOptions: Options;

    @Input() fromRegion: MapRegion;
    @Input() toRegion: MapRegion;

    @Output() regionReinforced = new EventEmitter<any>();
    @Output() closed = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fromRegion && changes.fromRegion.currentValue) {
            this.availableTroops = changes.fromRegion.currentValue.troops - 1;

            this.sliderOptions = {
                floor: 0,
                ceil: this.fromRegion.troops - 1
            };
        }
    }

    // key binding
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {

        switch (event.key) {
            case '+':
            case 'ArrowRight':
                this.incrementTroops();
                break;

            case '-':
            case 'ArrowLeft':
                this.decrementTroops();
                break;

            case 'r':
            case 'R':
                this.reinforce();
                break;

            case 'a':
            case 'A':
                this.reinforceAll();
                break;

            case 'n':
            case 'N':
            case 'Escape':
                this.closed.emit();
                break;
        }
    }

    incrementTroops() {
        if (this.troopsToReinforce < this.availableTroops) {
            this.troopsToReinforce++;
        }
    }

    decrementTroops() {
        if (this.troopsToReinforce > 0) {
            this.troopsToReinforce--;
        }
    }

    reinforce() {
        if (this.troopsToReinforce > 0) {
            this.regionReinforced.emit({
                from: this.fromRegion.id,
                to: this.toRegion.id,
                troops: this.troopsToReinforce
            });
        }
    }

    reinforceAll() {
        this.regionReinforced.emit({
            from: this.fromRegion.id,
            to: this.toRegion.id,
            troops: this.availableTroops
        });
    }
}
