/**
 * Events are triggered for a server command / update
 */

import { ChatMessage, GamePlayer, GamePreferences, GameRegion, GameRound, GameStateModel, GameTurn } from '@game/store/models/game.model';


export class Event {
    constructor(public payload?: any) {}
}


export class GameUpdatedEvent {
    static type = '[Game] < Game Updated';
    constructor(public payload: GameStateModel) {}
}

export class GamePreferencesUpdatedEvent {
    static type = '[Game] < Game Preferences Updated';
    constructor(public payload: GamePreferences) {}
}

export class GameRecordUpdatedEvent {
    static type = '[Game] < Game Record Updated';
    constructor(public payload: any[]) {}
}


/**
 * Side effects
 */

export class PlayerJoinedEvent extends Event {
    static type = '[Game] < Player Joined';
}

export class PlayerLeftEvent extends Event {
    static type = '[Game] < Player Left';
}


export class RoundStartedEvent {
    static type = '[Game] < Round Started';
    constructor(public payload: { troopsDue: number, round: GameRound }) {}
}

export class RoundEndedEvent {
    static type = '[Game] < Round Ended';
    // TODO: type deferredTroops ({troops: number, regions: number})
    constructor(public payload: { timedOut: boolean, deferredTroops: any, round: GameRound }) {}
}

export class TurnStartedEvent {
    static type = '[Game] < Turn Started';
    constructor(public payload: { turn: GameTurn }) {}
}

export class TurnBeganEvent {
    static type = '[Game] < Turn Began';
    constructor(public payload: { round: GameRound }) {}
}

export class TurnEndedEvent {
    static type = '[Game] < Turn Ended';
    // TODO: type deferredTroops ({troops: number, regions: number})
    constructor(public payload: { timedOut: boolean, deferredTroops: any, turn: GameTurn }) {}
}


export class GameStartedEvent {
    static type = '[Game] < Game Started';
    constructor(public payload: {
        players: GamePlayer[],
        regions: { [region: string]: GameRegion },
        target?: number,
        troopsOnCallUp: number
    }) {}
}

export class GamePausedEvent extends Event {
    static type = '[Game] < Game Paused';
}

export class GameResumedEvent extends Event {
    static type = '[Game] < Game Resumed';
}

export class GameEndedEvent extends Event {
    static type = '[Game] < Game Ended';
}

export class GameCancelledEvent extends Event {
    static type = '[Game] < Game Cancelled';
}


export class DeploymentStartedEvent {
    static type = '[Game] < Deployment Started';
    constructor(public payload: { troopsDue: number, player: number }) {}
}

export class TroopsDeployedEvent {
    static type = '[Game] < Troops Deployed';
    constructor(public payload: { player: number, region: string, count: number, deferred: boolean }) {}
}

export class DeploymentEndedEvent {
    static type = '[Game] < Deployment Ended';
    constructor(public payload: { player: number }) {}
}


export class AssaultStartedEvent {
    static type = '[Game] < Assault Started';
    constructor(public payload: { player: number }) {}
}

export class RegionAttackedEvent extends Event {
    static type = '[Game] < Region Attacked';

    /*
    "toRegionTroops": 4,
    "attackResult": "ll-",
    "attackDice": [4, 2, 2],
    "fromRegion": "siberia",
    "defender": 98355,
    "timestamp": 1540752651834,
    "fromRegionTroops": 4,
    "toRegion": "russian-plain",
    "attacker": 164982,
    "defendResult": "ww",
    "wasBombardment": false,
    "kind": "region_attacked",
    "defendDice": [5, 3]
    */
}

export class RegionBlitzedEvent extends Event {
    static type = '[Game] < Region Blitzed';
}

export class RegionConqueredEvent extends Event {
    static type = '[Game] < Region Conquered';
}

export class RegionBombardedEvent extends Event {
    static type = '[Game] < Region Bombarded';
}

export class RegionAdvancedEvent extends Event {
    static type = '[Game] < Region Advanced';
}

export class AssaultEndedEvent {
    static type = '[Game] < Assault Ended';
    constructor(public payload: { player: number }) {}

    /*
    kind: "assault_ended"
    player: 164982
    timestamp: 1541803802718
     */
}


export class ReinforcementStartedEvent extends Event {
    static type = '[Game] < Reinforcement Started';
}

export class RegionReinforcedEvent extends Event {
    static type = '[Game] < Region Reinforced';
}

export class ReinforcementEndedEvent extends Event {
    static type = '[Game] < Reinforcement Ended';
}


export class PlayerDefeatedEvent extends Event {
    static type = '[Game] < Player Defeated';
}

export class PointsAwardedDueToPlayerDefeatEvent extends Event {
    static type = '[Game] < Points Awarded due to Player Defeat';
}

export class PlayerGoneAWOLEvent extends Event {
    static type = '[Game] < Player Gone AWOL';
}

export class PlayerReturnedToBaseEvent extends Event {
    static type = '[Game] < Player Returned to Base';
}

export class PlayerBecameActiveEvent extends Event {
    static type = '[Game] < Player Became Active';
}


export class DeferredTroopsDeploymentStartedEvent extends Event {
    static type = '[Game] < Deferred Troops Deployment Started';
}

export class DeferredTroopsDeploymentEndedEvent extends Event {
    static type = '[Game] < Deferred Troops Deployment Ended';
}

export class ReserveAwardedEvent extends Event {
    static type = '[Game] < Reserve Awarded';
}

export class ReserveCallUpStartedEvent extends Event {
    static type = '[Game] < Reserve Call-up Started';
}

export class ReserveCallUpEndedEvent extends Event {
    static type = '[Game] < Reserve Call-up Ended';
    // TODO: typing
}

export class ReserveSetCalledUpEvent extends Event {
    static type = '[Game] < Reserve Set Called-up';
}

export class RegionAutoDeployedEvent extends Event {
    static type = '[Game] < Region Auto Deployed';
}

export class ChatUpdatedEvent {
    static type = '[Game] < Chat Updated';
    // TODO: typing
    constructor(public messages: ChatMessage[]) {}
}

export class ReinforceableRegionsUpdatedEvent {
    static type = '[Game] < Reinforceable Regions';
    constructor(public reinforcements: any) {}
}

export class UpdateClockEvent {
    static type = '[Game] < Update Clock';
    constructor(public timestamp: number) {}
}
