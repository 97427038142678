import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GamePlayer } from '@game/store/models/game.model';

@Component({
    selector: 'app-victory-popup',
    templateUrl: './victory-popup.component.html',
    styleUrls: ['./victory-popup.component.scss']
})
export class VictoryPopupComponent implements OnInit {

    @Input() winner: GamePlayer;
    @Input() message: string;
    @Input() pointsAwarded: any;
    @Input() turnsTaken: number;
    @Input() timeTaken: number;

    @Output() dismiss = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
