import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-chatter-message',
    templateUrl: './chatter-message.component.html',
    styleUrls: ['./chatter-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatterMessageComponent {

    @Input() message;

    constructor() { }
}
