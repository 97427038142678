import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-record-entry',
    templateUrl: './record-entry.component.html',
    styleUrls: ['./record-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordEntryComponent implements OnInit {

    @Input() entry: any;

    constructor() { }

    ngOnInit() {
    }

    getLosers(points: { [playerId: string]: number }, winners: number[]): number[] {
        return Object.keys(points)
            .map(playerId => parseInt(playerId, 10))
            .filter(playerId => !winners.includes(playerId))
        ;
    }
}
