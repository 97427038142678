import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MapEventsService } from '@game/services/map-events.service';
import { GameFacadeService } from '@game/facades/game-facade.service';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[appPlayerRegionsHighlight]'
})
export class PlayerRegionsHighlightDirective implements OnInit, OnDestroy {

    private player: number;
    private unlisteners: Function[] | null;

    @Input()
    set appPlayerRegionsHighlight(player: number) {
        this.player = player;
    }

    constructor(private mapEventsService: MapEventsService,
                private gameFacadeService: GameFacadeService,
                private zone: NgZone,
                private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        // run the listeners outside zone in order for the view not being updated everytime those events are triggered
        this.zone.runOutsideAngular(
            () => {
                this.unlisteners = [
                    this.renderer.listen( this.elementRef.nativeElement, 'mouseenter', this.onMouseOver ),
                    this.renderer.listen( this.elementRef.nativeElement, 'mouseleave', this.onMouseLeave )
                ];

            }
        );
    }

    onMouseOver = (): void => {
        if (this.player) {
            this.gameFacadeService.getPlayerRegions(this.player)
                .pipe(
                    take(1)
                )
                .subscribe(regions => this.mapEventsService.highlightRegions(regions))
            ;
        }
    }
    onMouseLeave = (): void => {
        this.mapEventsService.highlightRegions([]);
    }

    ngOnDestroy(): void {
        // If we have event-handler bindings, unbind them all.
        if (this.unlisteners) {
            for (const unlistener of this.unlisteners) {
                unlistener();
            }
        }
    }
}
