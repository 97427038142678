import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Options } from 'ng5-slider';
import { MapRegion } from '@game/models/ui-map';

@Component({
    selector: 'app-deploy-popup',
    templateUrl: './deploy-popup.component.html',
    styleUrls: ['./deploy-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeployPopupComponent implements OnInit {

    troopsToDeploy = 0;

    sliderOptions: Options;

    @Input() availableTroops: number;
    @Input() region: MapRegion;
    @Input() deferredDeployment: boolean;

    @Output() troopsDeployed = new EventEmitter<any>();
    @Output() closed = new EventEmitter();

    constructor() { }

    ngOnInit() {
        this.sliderOptions = {
            floor: 0,
            ceil: this.availableTroops,
            disabled: this.deferredDeployment
        };

        if (this.deferredDeployment) {
            this.troopsToDeploy = this.availableTroops;
        }
    }

    // key binding
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {

        switch (event.key) {
            case '+':
            case 'ArrowRight':
                this.incrementTroops();
                break;

            case '-':
            case 'ArrowLeft':
                this.decrementTroops();
                break;

            case 'd':
            case 'D':
                this.deployTroops();
                break;

            case 'a':
            case 'A':
                this.deployAllTroops();
                break;

            case 'n':
            case 'N':
            case 'Escape':
                this.closed.emit();
                break;
        }
    }

    incrementTroops() {
        if (!this.deferredDeployment && this.troopsToDeploy < this.availableTroops) {
            this.troopsToDeploy++;
        }
    }

    decrementTroops() {
        if (!this.deferredDeployment && this.troopsToDeploy > 0) {
            this.troopsToDeploy--;
        }
    }

    deployTroops() {
        if (this.isSelectionValid()) {
            this.troopsDeployed.emit({
                region: this.region.id,
                troops: this.troopsToDeploy
            });
        }
    }

    deployAllTroops() {
        this.troopsDeployed.emit({
            region: this.region.id,
            troops: this.availableTroops
        });
    }

    isSelectionValid() {
        return !this.deferredDeployment ? this.troopsToDeploy > 0 : this.troopsToDeploy === this.availableTroops;
    }
}
