import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapEventsService implements OnDestroy {

    private highlightSource = new BehaviorSubject<string[]>([]);
    public highlight$ = this.highlightSource.asObservable();

    constructor() { }

    highlightRegions(regions: string[]) {
        this.highlightSource.next(regions);
    }

    ngOnDestroy(): void {
        this.highlightSource.complete();
    }
}
