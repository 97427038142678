import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReserveColor } from '@game/store/models/game.model';

@Component({
    selector: 'app-reserve',
    templateUrl: './reserve.component.html',
    styleUrls: ['./reserve.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReserveComponent {

    @Input() name: string;
    @Input() region: string;
    @Input() color: ReserveColor;
    @Input() owned: boolean;
    @Input() state: ReserveCardState;
    @Input() selectable: false;

    reserveColor = ReserveColor;
    states = ReserveCardState;

    constructor() { }
}

export enum ReserveCardState {
    VALID = 'valid',
    INVALID = 'invalid'
}
