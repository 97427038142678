import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-dice-result',
    templateUrl: './dice-result.component.html',
    styleUrls: ['./dice-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiceResultComponent implements OnInit {

    @Input() values: number[];
    @Input() result: string;

    constructor() { }

    ngOnInit() {
    }

}
