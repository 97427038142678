import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ColorSelectorService {

    currentColorSelectorOpen$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() { }

    openDropdown(id: string) {
        this.currentColorSelectorOpen$.next(id);
    }

    closeDropdown() {
        this.currentColorSelectorOpen$.next(null);
    }
}
