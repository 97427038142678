import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProgressService {

    private progress$ = new BehaviorSubject<number>(0);
    private stepWeights = [
        {
            step: ProgressStep.MapInfo, weight: 1
        },
        {
            step: ProgressStep.RegionCanvasObjects, weight: 5
        },
        {
            step: ProgressStep.TitleCanvasObjects, weight: 3
        },
        {
            step: ProgressStep.ContainerCanvasObjects, weight: 3
        },
        {
            step: ProgressStep.MapRepresentation, weight: 1
        }
    ];
    private stepsStatus;

    constructor() {
        // build steps status
        this.stepsStatus = this.stepWeights.reduce((acc, entry) => {
            acc[entry.step] = {
                weight: entry.weight,
                loaded: false
            };
            return acc;
        }, {});
    }

    getProgress(): Observable<number> {
        return this.progress$.asObservable();
    }

    stepLoaded(step: ProgressStep) {
        this.stepsStatus[step].loaded = true;
        const progress = this.computeProgress();

        this.progress$.next(progress);
        if (progress === 100) {
            this.progress$.complete();
        }
    }

    isStepLoaded(step: ProgressStep): boolean {
        return this.stepsStatus[step].loaded;
    }

    private computeProgress(): number {
        const data = this.stepWeights.reduce((acc, entry) => {
            acc.totalWeights += entry.weight;
            const status = this.stepsStatus[entry.step];
            acc.weightsLoaded += status.loaded ? entry.weight : 0;
            return acc;
        }, {
            totalWeights: 0,
            weightsLoaded: 0
        });

        return Math.ceil(data.weightsLoaded * 100 / data.totalWeights);
    }
}

export enum ProgressStep {
    MapInfo                = 'map_info',
    RegionCanvasObjects    = 'region_canvas_objects',
    TitleCanvasObjects     = 'title_canvas_objects',
    ContainerCanvasObjects = 'container_canvas_objects',
    MapRepresentation      = 'map_representation',
    MapLoaded              = 'map_loaded'
}
