import { Injectable } from '@angular/core';
import { GamePlayer, GameStatus } from '@game/store/models/game.model';

@Injectable({
    providedIn: 'root'
})
export class StatusMessageResolverService {

    constructor() { }

    getMessage(status: GameStatus, playersTurn: boolean, currentPlayer: GamePlayer) {

        // console.log('CALCULATING MESSAGE', status, playersTurn, currentPlayer);

        if (status === GameStatus.WaitingForPlayers) {
            return 'Waiting for players';
        } else if (currentPlayer) {

            switch (status) {
                case GameStatus.WaitingToStartTurn:
                    return playersTurn ?
                        'It is your turn' :
                        `Waiting for player ${currentPlayer.name}`;

                case GameStatus.Deployment:
                    return playersTurn ?
                        `You have ${currentPlayer.troopsDue} troops to deploy` :
                        `${currentPlayer.name} is deploying`;

                case GameStatus.Attack:
                case GameStatus.Advance:
                    return playersTurn ?
                        'Click the regions you want to assault from/to' :
                        `${currentPlayer.name} is assaulting`;

                case GameStatus.Reinforcement:
                    return playersTurn ?
                        'Reinforce your regions' :
                        `${currentPlayer.name} is reinforcing`;

                case GameStatus.DeferredTroopsDeployment:
                    return playersTurn ?
                        `Deploy your deferred troops (${currentPlayer.deferredTroops})` :
                        `${currentPlayer.name} is deploying deferred troops`;

                case GameStatus.ReserveCallUp:
                    return playersTurn ?
                        'You may call up reserves' :
                        `${currentPlayer.name} is deploying`;

                case GameStatus.Paused:
                    return 'The game is paused';

                case GameStatus.Ended:
                    return 'Game Ended';

                case GameStatus.Cancelled:
                    // TODO: custom message on cancelled
                    return 'The game is cancelled because all players are AWOL';
            }
        }

        return '';
    }
}
