import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MapEventsService } from '@game/services/map-events.service';

@Directive({
    selector: '[appRegionHighlight]'
})
export class RegionHighlightDirective implements OnInit, OnDestroy {

    private region: string;
    private unlisteners: Function[] | null;

    @Input()
    set appRegionHighlight(region: string) {
        this.region = region;
    }

    constructor(private mapEventsService: MapEventsService,
                private zone: NgZone,
                private elementRef: ElementRef,
                private renderer: Renderer2) {
        // console.log('build region highlight directive');
    }

    ngOnInit(): void {
        // run the listeners outside zone in order for the view not being updated everytime those events are triggered
        this.zone.runOutsideAngular(
            () => {
                this.unlisteners = [
                    this.renderer.listen( this.elementRef.nativeElement, 'mouseenter', this.onMouseOver ),
                    this.renderer.listen( this.elementRef.nativeElement, 'mouseleave', this.onMouseLeave )
                ];

            }
        );
    }

    onMouseOver = (): void => {
        this.mapEventsService.highlightRegions([this.region]);
    }
    onMouseLeave = (): void => {
        this.mapEventsService.highlightRegions([]);
    }

    ngOnDestroy(): void {
        // If we have event-handler bindings, unbind them all.
        if (this.unlisteners) {
            for (const unlistener of this.unlisteners) {
                unlistener();
            }
        }
    }
}
