import { GameRegion } from '../store/models/game.model';

/**
 * Map information to be loaded
 */
export interface MapData {
    regions: MapDataRegion[];
    containers: MapDataContainer[];
    extension: string;
    victoryText: string;
    map: any;
}

export interface MapDataRegion {
    attacks: string[];
    bombardments: string[];
    container: object;
    id: string;
    idx: number;
    image: any;
    name: string;
    reinforcements: string[];
    sprite: any;
    title: any;
}

export interface MapDataContainer {
    id: string;
    textColor: string;
    '2digit': MapDataContainerInfo;
    '3digit': MapDataContainerInfo;
}

export interface MapDataContainerInfo {
    x: number;
    y: number;
    font: string;
    alignment: string;
    fontSize: number;
    h: number;
    w: number;
}

export interface MapAssets {
    map: string;
    brief: string;
    containers: string;
    regions: string;
    titles: string;
}

export interface MapInfo {
    assets: MapAssets;
    data: MapData;
}

export interface MapRegion {
    id: string;
    name: string;
    player: number;
    container: string;
    troops: number;
    attacks: string[];
    bombardments: string[];
    reinforcements: string[];
    team?: number;
}

/**
 * Class representation of the UI map containing auxiliary functions with game logic
 */
export class UIMap {

    regions: { [id: string]: MapRegion };
    teams: { [player: number]: number };

    constructor(regions: GameRegion[], playersColors: any, regionsPropertiesList: MapDataRegion[], reinforceableRegions: any, teams: any) {

        if (!regions) {
            // no regions are provided so we don't build the representation yet
            this.regions = {};
            return;
        }

        this.teams = teams;

        const regionsProperties = this.getRegionsProperties(regionsPropertiesList, reinforceableRegions);

        this.regions = Object.keys(regions).reduce((acc, regionId) => {
            const player = regions[regionId].player;
            const team   = teams[player];

            acc[regionId] = {
                ...regions[regionId],
                ...regionsProperties[regionId],
                team,
                container: playersColors[player] ? playersColors[player] : null
            };

            return acc;
        }, {});

    }

    public isOwner(region: string, player: number): boolean {
        return this.regions[region].player === player;
    }

    public getPlayerRegions(player: number): string[] {
        return Object.entries(this.regions)
            .filter(([name, info]) => info.player === player)
            .map(([name, info]) => name)
        ;
    }

    public canDeploy(playerId: number, regionId: string) {
        return this.regions[regionId].player === playerId || this.regions[regionId].team === this.teams[playerId];
    }

    public canAttackFrom(playerId: number, regionId: string) {
        const region = this.regions[regionId];
        return region.player === playerId && region.troops > 1;
    }

    public canAttack(playerId: number, fromRegionId: string, toRegionId: string) {
        return this.getAttackableRegions(playerId, fromRegionId).includes(toRegionId);
    }

    public canReinforceFrom(playerId: number, regionId: string) {
        const region = this.regions[regionId];
        return region.player === playerId && region.troops > 1;
    }

    public canReinforce(playerId: number, fromRegionId: string, toRegionId: string) {
        const fromRegion = this.regions[fromRegionId];
        return fromRegion.player === playerId && fromRegion.reinforcements.includes(toRegionId);
    }

    public getAdjacentRegions(region: string): string[] {
        return this.regions[region].attacks;
    }

    public getAttackableRegions(playerId: number, region: string): string[] {
        return [...this.regions[region].attacks, ...this.regions[region].bombardments]
            .filter(r => this.regions[r].player !== playerId)
        ;
    }

    public getReinforceableRegions(playerId: number, region: string): string[] {
        return this.regions[region].reinforcements;
    }


    /**
     * Transform list of mapdata region info list into an indexable table by region id containing the relevant properties for the region
     */
    private getRegionsProperties(regions: MapDataRegion[], reinforceableRegions): any {

        const lookupCache = regions.reduce((acc, region) => {
            acc[region.idx] = region.id;
            return acc;
        }, {});

        return regions.reduce((_regions, region: MapDataRegion) => {
            _regions[region.id] = {
                id: region.id,
                name: region.name,
                attacks: region.attacks,
                bombardments: region.bombardments,
                reinforcements: reinforceableRegions[region.id] ? reinforceableRegions[region.id].map(idx => lookupCache[idx]) : []
            };
            return _regions;
        }, {});
    }
}
