import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reserve } from '@game/store/models/game.model';
import { ReserveCardState } from '@game/components/reserve/reserve.component';

const MAX_CARDS = 3;

@Component({
    selector: 'app-reserves-popup',
    templateUrl: './reserves-popup.component.html',
    styleUrls: ['./reserves-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservesPopupComponent implements OnInit {

    @Input() reserves: Reserve[];
    @Input() troopsDue: number;
    @Input() mandatory: boolean;

    @Output() skipped = new EventEmitter();
    @Output() calledUp = new EventEmitter<Reserve[]>();

    selectedReserves: Reserve[];
    selectionValid: boolean;
    validReserves: Reserve[];

    constructor() { }

    ngOnInit() {
        this.selectedReserves = [];
        this.selectionValid = false;
        this.validReserves = [];
    }

    selectReserve(reserve: Reserve) {
        if (!this.isReserveSelected(reserve)) {
            if (this.selectedReserves.length < 3) {
                // reserve is not selected yet and there's still space, add it
                this.selectedReserves.push(reserve);
            }
        } else {
            // remove from reserves
            this.selectedReserves.splice(this.selectedReserves.indexOf(reserve), 1);
        }

        // start validation
        this.validateReserves();
    }

    private validateReserves() {
        // first two elements are always valid
        this.validReserves = [...this.selectedReserves.filter((_, idx) => idx < MAX_CARDS - 1)];

        // if all cards are selected check the third one
        if (this.selectedReserves.length === MAX_CARDS) {
            // check if the first two have the same color
            const colors = this.validReserves.map(reserve => reserve.color);
            const checkSameColor = colors[0] === colors[1];
            const thirdCard = this.selectedReserves[MAX_CARDS - 1];
            let thirdCardValid: boolean;

            if (checkSameColor) {
                // do the check by same color
                thirdCardValid = colors[0] === thirdCard.color;
            } else {
                // do the check by different color
                thirdCardValid = colors[0] !== thirdCard.color && colors[1] !== thirdCard.color;
            }

            if (thirdCardValid) {
                // card is valid, add it to the valid reserves
                this.validReserves.push(thirdCard);
            }
        }

        // selection is valid if the size of selected is the same as the size of valid and it's 3
        this.selectionValid = this.validReserves.length === this.selectedReserves.length && this.selectedReserves.length === MAX_CARDS;
    }

    getReserveState(reserve: Reserve) {
        if (!this.isReserveSelected(reserve)) {
            // reserve is not selected, so it doesn't have a validation status
            return null;
        }

        return this.validReserves.includes(reserve) ? ReserveCardState.VALID : ReserveCardState.INVALID;
    }

    private isReserveSelected(reserve: Reserve) {
        return this.selectedReserves.includes(reserve);
    }

    callUpReserves() {
        if (this.selectionValid) {
            this.calledUp.emit(this.selectedReserves.map(reserve => {
                return {
                    region: reserve.region,
                    color: reserve.color
                };
            }));
        }
    }

    skipCallUp() {
        if (!this.mandatory) {
            this.skipped.emit();
        }
    }
}
