import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-round',
    templateUrl: './round.component.html',
    styleUrls: ['./round.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundComponent {

    @Input() roundNumber: number;

    constructor() { }

}
