import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GameOptions } from '@game/store/models/game.model';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-debriefing-popup',
    templateUrl: './debriefing-popup.component.html',
    styleUrls: ['./debriefing-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebriefingPopupComponent implements OnInit, OnChanges {

    STAR_COUNT = 8;

    @Input() gameId: number;
    @Input() options: GameOptions;
    @Input() progress: number;
    @Output() dismiss = new EventEmitter();

    private randomTexts = [
        'Lorem ipsum dolor sit amet',
        'consectetur adipiscing elit',
        'Praesent cursus consectetur',
        'Curabitur rutrum felis porta',
        'auctor risus sed, facilisis eros',
        'Pellentesque viverra vestibulum',
        'Proin in libero malesuada massa'
    ];

    randomText$: Observable<string>;
    stars: number[];
    filledStars = 0;

    constructor() {
        this.stars = new Array(this.STAR_COUNT);
    }

    ngOnInit(): void {
        // automatically iterate over random text
        this.randomText$ = timer(0, 2000).pipe(
            map(i => {
                const idx = i % this.randomTexts.length;
                return this.randomTexts[idx];
            }),
            takeWhile(() => this.progress < 100)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.progress && changes.progress.currentValue !== changes.progress.previousValue) {
            this.filledStars = Math.ceil(changes.progress.currentValue * this.STAR_COUNT / 100);
        }
    }

    getTurnLength(length: number) {
        const minutes = length / 60;

        // TODO: this may have problem if we have for example turns of 1 hour and half

        if (minutes >= 60) {
            // return in hours
            return (minutes / 60) + ' (Hours)';
        } else {
            // return in minutes
            return minutes + '(Minutes)';
        }
    }
}
