import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MessageHandlerService } from '@game/services/message-handler.service';

@Component({
    selector: 'app-debug',
    templateUrl: './debug.component.html',
    styleUrls: ['./debug.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugComponent implements OnInit {

    constructor(private messageHandler: MessageHandlerService) { }

    ngOnInit() {
    }

    injectMessage(message: string) {
        this.messageHandler.handleMessage(JSON.parse(message));
    }
}
