import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges
} from '@angular/core';
import { ColorSelectorService } from '@game/services/color-selector.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorSelectorComponent implements OnInit, OnChanges, OnDestroy {

    private screenHeight: number;
    private screenWidth: number;

    colors: any[];
    displayList = false;
    positionV: string;
    positionH: string;

    @Input() size = 'normal';
    @Input() selectedColor: string;
    @Input() id: string;
    @Input()
    set containers(containers: any) {

        if (containers) {
            this.colors = Object.keys(containers).map(containerId => {
                return {
                    id: containerId,
                    img: containers[containerId]
                };
            });
        }
    }

    @Output() changed = new EventEmitter<string>();

    private onDestroy$ = new Subject<boolean>();

    constructor(private el: ElementRef, private colorSelectorService: ColorSelectorService) {
        this.getScreenSize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.id) {
            this.colorSelectorService.currentColorSelectorOpen$
                .pipe(
                    takeUntil(this.onDestroy$)
                )
                .subscribe(val => {
                    this.displayList = val === this.id;
                    if (this.displayList) {
                        // right now list is open. Compute to where it should open
                        this.computePosition();
                    }
                })
            ;
        }
    }

    ngOnInit() {}

    toggleDropdown(event) {
        if (this.displayList) {
            this.colorSelectorService.closeDropdown();
        } else {
            this.colorSelectorService.openDropdown(this.id);
        }
        event.stopPropagation();
    }

    closeDropdown() {
        this.colorSelectorService.closeDropdown();
    }

    selectColor(colorId: string) {
        this.changed.emit(colorId);
    }

    private computePosition() {
        const rect = this.el.nativeElement.getBoundingClientRect();
        this.positionV = rect.top > this.screenHeight / 2 ? 'top' : 'bottom';
        this.positionH = rect.left > this.screenWidth / 2 ? 'left' : 'right';
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
