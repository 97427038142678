import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-chatter',
    templateUrl: './chatter.component.html',
    styleUrls: ['./chatter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatterComponent implements OnInit, OnChanges {

    scope = 'game';

    @Input() messages;
    @Input() teamEnabled: boolean;
    @Input() chatEnabled: boolean;
    @Output() messageSent = new EventEmitter<any>();

    @ViewChild('input') messageInputRef: ElementRef;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.scope = this.teamEnabled ? 'team' : 'game';
    }

    sendMessage(message: string, scope: string) {
        if (message && scope) {
            this.messageSent.emit({
                message,
                scope
            });
            this.messageInputRef.nativeElement.value = '';
            this.messageInputRef.nativeElement.style.height = 'auto';
        }
    }

    trackElement(index: number, element: any) {
        return element.timestamp;
    }

    selectScope(scope) {
        this.scope = scope;
    }
}
