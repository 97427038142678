import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionButtonState } from '@game/util/action-button-resolver.service';

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonComponent {

    public State = ActionButtonState;

    @Input() action: string;

    @Output() beginTurn = new EventEmitter();
    @Output() endAssault = new EventEmitter();
    @Output() endReinforcement = new EventEmitter();
    @Output() endTurn = new EventEmitter();
    @Output() skipDeferred = new EventEmitter();
    @Output() skipReserveCallUp = new EventEmitter();
    @Output() joinGame = new EventEmitter();
    @Output() leaveGame = new EventEmitter();

    constructor() { }

}
