import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-region-info',
    templateUrl: './region-info.component.html',
    styleUrls: ['./region-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionInfoComponent implements OnInit {

    @Input() regionId: string;

    constructor() { }

    ngOnInit() {
    }

}
