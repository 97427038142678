import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform a time into days / hours / minutes / seconds
 */
@Pipe({
    name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

    private fullNotation: boolean;
    private separator = ':';

    private times = {
        h: 3600,
        m: 60,
        s: 1
    };

    transform(value: any, args?: any): any {

        if (value === null) {
            return '...';
        }

        this.fullNotation = args && args[0] ? args[0] : false;

        if (this.fullNotation) {
            this.separator = ' ';

            this.times = Object.assign({ d: 86400 }, this.times);
        }

        let time_string = '';
        let letter_fixed = false;

        const pad2 = (number) => {
            return (number < 10 ? '0' : '') + number;
        };

        for (const key of Object.keys(this.times)) {
            const aux = Math.floor(value / this.times[key]);

            if (aux > 0) {
                letter_fixed = true;
            }

            if (aux > 0 || letter_fixed || key === 's') {

                time_string += pad2(aux.toString()) + this.getUnitName(key.toString()) + this.separator;
                value = value - this.times[key] * aux;
            }
        }

        return time_string.substr(0, time_string.length - 1);
    }

    getUnitName(unitId: string): string {
        if (!this.fullNotation) {
            return unitId;
        }

        switch (unitId) {
            case 'd':
                return ' days';
            case 'h':
                return ' hours';
            case 'm':
                return ' minutes';
            case 's':
                return ' seconds';
        }
    }
}
