import { Injectable } from '@angular/core';
import { GameStatus } from '@game/store/models/game.model';

@Injectable({
  providedIn: 'root'
})
export class ActionButtonResolverService {

  constructor() { }

  getActionState(status: GameStatus,
                 hasJoinedGame: boolean,
                 playersTurn: boolean,
                 hasDeferredTroops: boolean,
                 reserveCallUpMandatory: boolean): ActionButtonState {

      // console.log('getting action', status, hasJoinedGame, playersTurn, hasDeferredTroops);

      if (status === GameStatus.WaitingForPlayers) {
          return !hasJoinedGame ? ActionButtonState.JoinGame : ActionButtonState.LeaveGame;
      } else if (playersTurn) {

          switch (status) {
              case GameStatus.WaitingToStartTurn:
                  return ActionButtonState.BeginTurn;

              case GameStatus.Deployment:
                  return ActionButtonState.DeployDisabled;

              case GameStatus.Attack:
                  return ActionButtonState.EndAssault;

              case GameStatus.Advance:
                  return ActionButtonState.EndAssaultDisabled;

              case GameStatus.Reinforcement:
                  return !hasDeferredTroops ? ActionButtonState.EndTurn : ActionButtonState.EndReinforce;

              case GameStatus.DeferredTroopsDeployment:
                  return ActionButtonState.SkipDeferred;

              case GameStatus.ReserveCallUp:
                  return !reserveCallUpMandatory ? ActionButtonState.SkipReserveCallUp : ActionButtonState.DeployDisabled;
          }
      }

      return ActionButtonState.Other;
  }
}

export enum ActionButtonState {
    BeginTurn          = 'begin_turn',
    DeployDisabled     = 'deploy',
    EndAssault         = 'end_assault',
    EndAssaultDisabled = 'end_assault_disabled',
    EndTurn            = 'end_turn',
    EndReinforce       = 'end_reinforce',
    SkipDeferred       = 'skip_deferred',
    SkipReserveCallUp  = 'skip_reserve_call_up',
    JoinGame           = 'join_game',
    LeaveGame          = 'leave_game',
    Other              = 'other'
}
