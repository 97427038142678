import { Pipe, PipeTransform } from '@angular/core';
import { GameFacadeService } from '@game/facades/game-facade.service';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'containerImg'
})
export class ContainerPipe implements PipeTransform {

    constructor(private gameFacadeService: GameFacadeService) {}

    transform(value: any, args?: any): any {

        return this.gameFacadeService.getContainersImages().pipe(
            map(containers => containers[value])
        );
    }

}
