import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameOptions } from '@game/store/models/game.model';

@Component({
    selector: 'app-brief',
    templateUrl: './brief.component.html',
    styleUrls: ['./brief.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BriefComponent {

    @Input() options: GameOptions;
    @Input() image: string;

    constructor() { }

    getTurnLength(length: number) {
        const minutes = length / 60;

        // TODO: this may have problem if we have for example turns of 1 hour and half

        if (minutes >= 60) {
            // return in hours
            return (minutes / 60) + ' (Hours)';
        } else {
            // return in minutes
            return minutes + '(Minutes)';
        }
    }
}
