import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-number-spinner',
    templateUrl: './number-spinner.component.html',
    styleUrls: ['./number-spinner.component.scss']
})
export class NumberSpinnerComponent implements OnInit {

    @Input() value: number;
    @Input() min: number;
    @Input() max: number;
    @Input() disabled: boolean;
    @Output() valueChange = new EventEmitter();

    @ViewChild('input') input: ElementRef;

    constructor() { }

    ngOnInit() {
    }

    increment() {
        if (this.value < this.max) {
            this.value++;
            this.valueChange.emit(this.value);
        }
    }

    decrement() {
        if (this.value > this.min) {
            this.value--;
            this.valueChange.emit(this.value);
        }
    }

    updateValue(inputValue: string) {
        
        // convert to integer
        const value = parseInt(inputValue, 10);

        if (value < this.min) {
            this.value = this.min;
        } else if (value > this.max) {
            this.value = this.max;
        } else {
            this.value = value;
        }

        // force update of input box
        this.input.nativeElement.value = this.value;

        this.valueChange.emit(this.value);
    }
}
