import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'diceInfo'
})
export class DiceInfoPipe implements PipeTransform {

    transform(diceValues: number[], result: string): any {

        let html = '';
        let i = 0;

        for (const value of diceValues) {

            let cssClass = '';

            switch (result.charAt(i)) {
                case 'w':
                    cssClass = 'white';
                    break;
                case 'l':
                    cssClass = 'black';
                    break;
                case '-':
                    cssClass = 'mid-gray';
                    break;
            }

            html += '<span class="ph2px ' + cssClass + '">' + value + '</span>';

            i++;
        }

        return html;
    }

}
