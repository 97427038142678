import { Pipe, PipeTransform } from '@angular/core';
import { GameFacadeService } from '@game/facades/game-facade.service';

@Pipe({
    name: 'regionName'
})
export class RegionNamePipe implements PipeTransform {

    constructor(private gameFacadeService: GameFacadeService) {}

    transform(value: any, args?: any): any {
        return this.gameFacadeService.getRegionName(value);
    }

}
