import { Pipe, PipeTransform } from '@angular/core';
import { GamePlayer, PlayerStatus } from '@game/store/models/game.model';

@Pipe({
    name: 'playerActiveFilter',
    pure: false
})
export class PlayerFilterPipe implements PipeTransform {

    transform(players: GamePlayer[], onlyActive: boolean): any {

        if (onlyActive) {
            return players.filter(player => player.state === PlayerStatus.Active);
        }

        return players;
    }

}
