import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Options } from 'ng5-slider';
import { MapRegion } from '@game/models/ui-map';
import { GameStatus } from '@game/store/models/game.model';

@Component({
    selector: 'app-assault-popup',
    templateUrl: './assault-popup.component.html',
    styleUrls: ['./assault-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssaultPopupComponent implements OnInit, OnChanges {

    @Input() fromRegion: MapRegion;
    @Input() toRegion: MapRegion;
    @Input() attacks;
    @Input() mode: GameStatus;

    @Output() regionAttacked = new EventEmitter();
    @Output() regionBlitzed = new EventEmitter();
    @Output() troopsAdvanced = new EventEmitter<number>();
    @Output() closed = new EventEmitter();

    troopsCount = 1;

    sliderOptions: Options;
    gameStatus = GameStatus;

    constructor() { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fromRegion && changes.fromRegion.currentValue) {
            // from Region changed, we need to update our limits
            this.sliderOptions = {
                floor: 1,
                ceil: this.fromRegion.troops - 1
            };
        }
    }

    // key binding
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {

        if (this.mode === GameStatus.Attack) {
            switch (event.key) {
                case 'a':
                case 'A':
                    this.attack();
                    break;

                case 'b':
                case 'B':
                    this.blitz();
                    break;

                case 'n':
                case 'N':
                case 'Escape':
                    this.retreat();
                    break;
            }
        } else if (this.mode === GameStatus.Advance) {
            switch (event.key) {
                case 'o':
                case 'O':
                case '1':
                    this.advanceTroops(1);
                    break;

                case 'a':
                case 'A':
                    this.advanceTroops(this.fromRegion.troops - 1);
                    break;

                case 'n':
                case 'N':
                    this.advanceTroops(this.troopsCount);
                    break;

                case '+':
                case 'ArrowRight':
                    this.incrementTroops();
                    break;

                case '-':
                case 'ArrowLeft':
                    this.decrementTroops();
                    break;
            }
        }
    }

    /**
     * Assault
     */

    attack() {
        this.regionAttacked.emit({
            from: this.fromRegion.id,
            to: this.toRegion.id
        });
    }

    blitz() {
        this.regionBlitzed.emit({
            from: this.fromRegion.id,
            to: this.toRegion.id
        });
    }

    retreat() {
        this.closed.emit();
    }

    /**
     * Advance
     */

    advanceTroops(count: number) {
        this.troopsAdvanced.emit(count);
    }

    incrementTroops() {
        if (this.troopsCount < this.fromRegion.troops - 1) {
            this.troopsCount++;
        }
    }

    decrementTroops() {
        if (this.troopsCount > 1) {
            this.troopsCount--;
        }
    }
}
