import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-paused-popup',
    templateUrl: './paused-popup.component.html',
    styleUrls: ['./paused-popup.component.scss']
})
export class PausedPopupComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
