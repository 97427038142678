import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-player-slots',
    templateUrl: './player-slots.component.html',
    styleUrls: ['./player-slots.component.scss']
})
export class PlayerSlotsComponent implements OnInit, OnChanges {

    slots = [];

    @Input() total: number;
    @Input() available: number;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.total && changes.available) {
            const filled = this.total - this.available;

            const slots = [];

            for (let i = 0; i < this.total; i++) {
                slots.push(i < filled ? true : false);
            }

            this.slots = slots;
        }
    }
}
